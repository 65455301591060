define("niffler/pods/components/button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    localClassNames: 'button',
    localClassNameBindings: ['typeClass', 'moldClass'],
    mold: 'default',
    type: 'confirm',
    typeClass: Ember.computed('type', function () {
      let type = Ember.get(this, 'type');
      return `button-${type}`;
    }),
    moldClass: Ember.computed('mold', function () {
      let mold = Ember.get(this, 'mold');
      return `button-${mold}`;
    })
  });

  _exports.default = _default;
});