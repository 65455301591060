define("niffler/pods/components/layout/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['layout'],
    localClassNameBindings: ['layoutMargin', 'layoutDirection', 'widthClass', 'contentAlign'],
    widthClass: Ember.computed('width', function () {
      switch (Ember.get(this, 'width')) {
        case 1:
          return 'expand-eight';

        case 2:
          return 'expand-quarter';

        case 3:
          return 'expand-half';

        case 4:
          return 'expand-th-q';

        case 5:
          return 'expand-full';

        default:
          return 'expand-none';
      }
    }),
    layoutMargin: Ember.computed('margin', function () {
      let margin = Ember.get(this, 'margin');
      return margin ? `margin-${margin}` : 'margin-default';
    }),
    layoutDirection: Ember.computed('direction', function () {
      let direction = Ember.get(this, 'direction');
      return direction ? `direction-${direction}` : 'direction-column';
    }),
    contentAlign: Ember.computed('content', function () {
      let direction = Ember.get(this, 'content');
      return direction ? `content-${direction}` : 'content-left';
    })
  });

  _exports.default = _default;
});