define("niffler/services/paginator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service('store'),
    entity_products: 'entities/{0}/products?page_number={1}&page_size={2}',
    entity_products_totalPages: 'entities/{0}/products/page-total?page_size={1}',
    rules_domains: 'rules/{0}/domains?page_number={1}&page_size={2}',
    rules_domains_totalPages: 'rules/{0}/domains/page-total?page_size={1}',
    priceLists_items: 'price-lists/{0}/items?page_number={1}&page_size={2}',
    priceLists_items_totalPages: 'price-lists/{0}/items/page-total?page_size={1}',

    formatURI(uri, ...params) {
      return uri.replace(/{(\d+)}/g, (match, number) => {
        return typeof params[number] !== 'undefined' ? params[number] : match;
      });
    },

    loadElements(id, size, type, modelName, pageNumber) {
      let store = Ember.get(this, 'store');
      return store.query(modelName, {
        endpointURI: this.formatURI(Ember.get(this, type), id, pageNumber, size)
      });
    },

    calculateTotalPages(id, size, type) {
      let store = Ember.get(this, 'store');
      return store.queryRecord('page-total', {
        endpointURI: this.formatURI(Ember.get(this, type), id, size)
      });
    },

    calculateArrayButtons(len, index) {
      return Array.from({
        length: len
      }, (_, k) => k + index);
    }

  });

  _exports.default = _default;
});