define("niffler/pods/components/niffler/panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['panel'],
    localClassNameBindings: ['panelSize', 'marginLayout', 'panelColor'],
    size: 'default',
    margin: 'none',
    marginLayout: Ember.computed('margin', function () {
      return `margin-${Ember.get(this, 'margin')}`;
    }),
    panelSize: Ember.computed('size', function () {
      return `size-${Ember.get(this, 'size')}`;
    }),
    panelColor: Ember.computed('color', function () {
      return `color-${Ember.get(this, 'color')}`;
    })
  });

  _exports.default = _default;
});