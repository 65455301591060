define("niffler/pods/components/fragments/entity/rules/render-rule/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "rule-row": "_rule-row_8wcrc",
    "active": "_active_8wcrc",
    "rules-grid": "_rules-grid_8wcrc",
    "btn-toggle-style": "_btn-toggle-style_8wcrc"
  };
  _exports.default = _default;
});