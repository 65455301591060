define("niffler/pods/components/niffler/tree-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['tree'],
    localClassNameBindings: ['nested:nested-tree'],
    tagName: 'ul',
    listItems: 'children',
    collapsed: true,
    selectedId: 0,
    nested: false,
    selected: Ember.computed('selectedId', 'data', function () {
      return Ember.get(this, 'data.id') === Ember.get(this, 'selectedId');
    }),
    icon: Ember.computed('collapsed', function () {
      return Ember.get(this, 'collapsed') ? 'angle-right' : 'angle-down'; //return get(this, 'collapsed') ? 'folder': 'folder-open';
    }),
    childrens: Ember.computed('data', function () {
      let column = Ember.get(this, 'listItems');
      let data = Ember.get(this, 'data');
      return Ember.get(data, column);
    }),
    actions: {
      changeState() {
        this.toggleProperty('collapsed');
      },

      launchAction() {
        let action = Ember.get(this, 'action');
        let data = Ember.get(this, 'data');

        if (action) {
          action(data);
        }
      }

    }
  });

  _exports.default = _default;
});