define("niffler/pods/components/niffler/paginate/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "paginate": "_paginate_fh000p",
    "paginate-pages": "_paginate-pages_fh000p",
    "paginate-center": "_paginate-center_fh000p",
    "pagination-button": "_pagination-button_fh000p",
    "pagination-pages-old": "_pagination-pages-old_fh000p",
    "dir-right": "_dir-right_fh000p",
    "dir-left": "_dir-left_fh000p",
    "design-default": "_design-default_fh000p",
    "pagination-button-dots": "_pagination-button-dots_fh000p",
    "active": "_active_fh000p",
    "pagination-angle": "_pagination-angle_fh000p",
    "pagination-pages": "_pagination-pages_fh000p",
    "pagination-pages-label": "_pagination-pages-label_fh000p",
    "pagination-input": "_pagination-input_fh000p"
  };
  _exports.default = _default;
});