define("niffler/pods/components/paginate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OAHnEjxM",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[9,\"div\",true],[13,\"class\",[32,[[31,0,0,[27,[26,4,\"CallHead\"],[]],[\"pagination\"],[[\"from\"],[\"niffler/pods/components/paginate/styles\"]]]]],null],[10],[1,1,0,0,\"\\n  \"],[9,\"button\",true],[13,\"class\",[32,[[31,0,0,[27,[26,4,\"CallHead\"],[]],[\"navButtons\"],[[\"from\"],[\"niffler/pods/components/paginate/styles\"]]]]],null],[13,\"onclick\",[31,76,6,[27,[26,5,\"CallHead\"],[]],[[27,[24,0],[]],\"previousPage\"],null],null],[10],[1,1,0,0,\"<\"],[11],[1,1,0,0,\"  \\n\"],[5,[27,[26,7,\"BlockHead\"],[]],[[31,0,0,[27,[26,6,\"CallHead\"],[]],[[31,0,0,[27,[26,6,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[\"numberOfButtons\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"button\",true],[13,\"class\",[32,[[31,0,0,[27,[26,4,\"CallHead\"],[]],[[31,190,6,[27,[26,0,\"CallHead\"],[]],[[31,203,2,[27,[26,3,\"CallHead\"],[]],[[31,207,2,[27,[26,2,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[\"activePage\"]],[31,232,6,[27,[26,0,\"CallHead\"],[]],[\"page-\",[27,[24,1],[]]],null]],null],\"active\",\"\"],null],\" carrousel\"],null]],[[\"from\"],[\"niffler/pods/components/paginate/styles\"]]]]],null],[13,\"onclick\",[31,297,6,[27,[26,5,\"CallHead\"],[]],[[27,[24,0],[]],\"goToPage\",[27,[24,1],[]]],null],null],[10],[1,1,0,0,\"\\n      \"],[1,0,0,0,[27,[24,1],[]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[1,1,0,0,\"  \"],[9,\"button\",true],[13,\"class\",[32,[[31,0,0,[27,[26,4,\"CallHead\"],[]],[\"navButtons\"],[[\"from\"],[\"niffler/pods/components/paginate/styles\"]]]]],null],[13,\"onclick\",[31,409,6,[27,[26,5,\"CallHead\"],[]],[[27,[24,0],[]],\"nextPage\"],null],null],[10],[1,1,0,0,\">\"],[11],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"concat\",\"pagesInfo\",\"eq\",\"if\",\"local-class\",\"action\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "niffler/pods/components/paginate/template.hbs"
    }
  });

  _exports.default = _default;
});