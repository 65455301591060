define("niffler/pods/components/niffler/layout/grid/row/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "grid-row": "_grid-row_fy04s0",
    "col-1": "_col-1_fy04s0",
    "col-2": "_col-2_fy04s0",
    "col-3": "_col-3_fy04s0",
    "col-4": "_col-4_fy04s0",
    "col-5": "_col-5_fy04s0",
    "col-6": "_col-6_fy04s0",
    "col-7": "_col-7_fy04s0",
    "col-8": "_col-8_fy04s0",
    "col-9": "_col-9_fy04s0",
    "col-10": "_col-10_fy04s0",
    "col-11": "_col-11_fy04s0",
    "col-12": "_col-12_fy04s0",
    "style-default": "_style-default_fy04s0",
    "style-overflow": "_style-overflow_fy04s0",
    "margin-none": "_margin-none_fy04s0",
    "margin-small": "_margin-small_fy04s0"
  };
  _exports.default = _default;
});