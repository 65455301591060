define("niffler/pods/components/panel/toolbar-button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "color-primary": "_color-primary_1vnumq",
    "color-secondary": "_color-secondary_1vnumq",
    "color-gray": "_color-gray_1vnumq",
    "color-cancel": "_color-cancel_1vnumq",
    "color-pdf": "_color-pdf_1vnumq",
    "color-excel": "_color-excel_1vnumq",
    "toolbar-button": "_toolbar-button_1vnumq",
    "design-form": "_design-form_1vnumq",
    "design-readonly": "_design-readonly_1vnumq"
  };
  _exports.default = _default;
});