define("niffler/pods/components/notification-mask/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uqvS92Ts",
    "block": "{\"symbols\":[],\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[27,[26,0,\"Expression\"],[\"hasNotifications\"]]],null,[[\"default\"],[{\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],null,[[\"type\"],[[27,[26,0,\"Expression\"],[\"notification\",\"type\"]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[1,0,0,0,[27,[26,0,\"Expression\"],[\"notification\",\"message\"]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"service\",\"notification-mask/notification\",\"if\"]}",
    "meta": {
      "moduleName": "niffler/pods/components/notification-mask/template.hbs"
    }
  });

  _exports.default = _default;
});