define("niffler/pods/components/search-box/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "search-box": "_search-box_md9qcd",
    "grow": "_grow_md9qcd",
    "search-icon": "_search-icon_md9qcd",
    "search-input": "_search-input_md9qcd _input_158mqq",
    "item-list": "_item-list_md9qcd",
    "element-item": "_element-item_md9qcd",
    "default": "_default_md9qcd",
    "label": "_label_md9qcd _label_158mqq",
    "left-label": "_left-label_md9qcd",
    "right-label": "_right-label_md9qcd",
    "bottom-label": "_bottom-label_md9qcd"
  };
  _exports.default = _default;
});