define("niffler/pods/components/forms/input-checkbox/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['form-input', 'input-checkbox'],
    localClassNameBindings: ['widthClass'],
    name: '',
    width: 2,
    widthClass: Ember.computed('width', function () {
      switch (Ember.get(this, 'width')) {
        case 1:
          return 'expand-eight';

        case 2:
          return 'expand-quarter';

        case 3:
          return 'expand-half';

        case 4:
          return 'expand-th-q';

        case 5:
          return 'expand-full';

        default:
          return 'expand-quarter';
      }
    })
  });

  _exports.default = _default;
});