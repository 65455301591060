define("niffler/pods/authorized/home/controller", ["exports", "niffler/config/environment", "fetch"], function (_exports, _environment, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    selected: {},
    pageSize: 10,
    numberOfButtons_default: 5,
    productsByEntity: {},
    domainsByRule: {},
    itemsByPricelist: {},
    categories: {
      system: {},
      selected: null,
      pricelist: {}
    },
    notifications: Ember.inject.service('notifications'),
    session: Ember.inject.service('session'),
    paginator: Ember.inject.service('paginator'),
    ajax: Ember.inject.service('niffler'),
    hermes: Ember.inject.service('i18n'),
    download: Ember.inject.service('file-downloader'),
    paginationModelInfo_entity_products: {},
    pagesInfo_entity_products: {},
    paginationModelInfo_rules_domains: {},
    pagesInfo_rules_domains: {},
    paginationModelInfo_priceList_items: {
      dataSize: 0,
      pageSize: 10,
      currentPage: 1
    },
    entities_params: Ember.computed('model.@each.id', function () {
      let entities = Ember.get(this, 'model');
      return entities.reduce((acc, entity) => {
        return acc += `&entity_id[]=${entity.id}`;
      }, '');
    }),
    readOnly: Ember.computed('selected', function () {
      let dateTo = Ember.get(this, 'selected.price-list.dateto');
      return dateTo ? new Date(dateTo) < new Date() : false;
    }),
    price_lists_system: {},
    collapsed: false,
    // TODO: Add hermes translations
    priceType: [{
      key: 'F',
      name: 'Fixed'
    }, {
      key: 'V',
      name: 'Variable'
    }, {
      key: 'D',
      name: 'Default'
    }],
    listType: [{
      key: 'P',
      name: 'Purchase'
    }, {
      key: 'S',
      name: 'Sell'
    }, {
      key: 'B',
      name: 'Both'
    }],
    currencyCode: [{
      key: 'MXN',
      name: 'Peso mexicano (MXN)'
    }, {
      key: 'USD',
      name: 'Dolars (USD)'
    }, {
      key: 'SAR',
      name: 'Saudi Arabian Riyal (SAR)'
    }],
    typesOfRule: [{
      key: 'BP',
      name: 'Company'
    }, {
      key: 'AR',
      name: 'Area'
    }, {
      key: 'CA',
      name: 'Category'
    }],

    reset(modelKey) {
      let model = Ember.get(this, modelKey);

      if (model) {
        if (typeof model.rollbackAttributes === "function") {
          model.rollbackAttributes();
        }
      }
    },

    create(modelKey, model) {
      Ember.set(this, modelKey, Ember.get(this, 'store').createRecord(model));
    },

    loadPaginationProductsEntity() {
      let entity = Ember.get(this, 'selected.entity');
      let paginator = Ember.get(this, 'paginator');
      Ember.set(this, 'productsByEntity', {});
      Ember.set(this, 'paginationModelInfo_entity_products', {
        id: entity.id,
        model: 'product',
        type: 'entity_products',
        size: Ember.get(this, 'pageSize')
      });
      Ember.set(this, 'pagesInfo_entity_products', {
        currentPage: 1,
        activePage: 'page-1',
        currentUpperLimit: Ember.get(this, 'numberOfButtons_default'),
        paginationIndex: 1,
        limitPage: 1,
        numberOfButtons: [],
        numberOfButtonsDefault: Ember.get(this, 'numberOfButtons_default')
      });
      paginator.calculateTotalPages(entity.id, Ember.get(this, 'pageSize'), 'entity_products_totalPages').then(response => {
        Ember.set(this, 'pagesInfo_entity_products.limitPage', response.size);

        if (response.size > Ember.get(this, 'numberOfButtons_default')) {
          Ember.set(this, 'pagesInfo_entity_products.numberOfButtons', paginator.calculateArrayButtons(Ember.get(this, 'numberOfButtons_default'), 1));
        } else {
          Ember.set(this, 'pagesInfo_entity_products.numberOfButtons', paginator.calculateArrayButtons(response.size, 1));
        }
      }).catch(() => {
        Ember.set(this, 'pagesInfo_entity_products.numberOfButtons', null);
      });
      paginator.loadElements(entity.id, Ember.get(this, 'pageSize'), 'entity_products', 'product', 1).then(response => {
        Ember.set(this, 'productsByEntity', response);
      }).catch(() => {
        Ember.set(this, 'productsByEntity', {});
      });
    },

    loadPaginationDomainsRules() {
      let rule = Ember.get(this, 'selected.rule');
      let paginator = Ember.get(this, 'paginator');
      Ember.set(this, 'domainsByRule', {});
      Ember.set(this, 'paginationModelInfo_rules_domains', {
        id: rule.id,
        model: 'domain',
        type: 'rules_domains',
        size: Ember.get(this, 'pageSize')
      });
      Ember.set(this, 'pagesInfo_rules_domains', {
        currentPage: 1,
        activePage: 'page-1',
        currentUpperLimit: Ember.get(this, 'numberOfButtons_default'),
        paginationIndex: 1,
        limitPage: 1,
        numberOfButtons: [],
        numberOfButtonsDefault: Ember.get(this, 'numberOfButtons_default')
      });
      paginator.calculateTotalPages(rule.id, Ember.get(this, 'pageSize'), 'rules_domains_totalPages').then(response => {
        Ember.set(this, 'pagesInfo_rules_domains.limitPage', response.size);

        if (response.size > Ember.get(this, 'numberOfButtons_default')) {
          Ember.set(this, 'pagesInfo_rules_domains.numberOfButtons', paginator.calculateArrayButtons(Ember.get(this, 'numberOfButtons_default'), 1));
        } else {
          Ember.set(this, 'pagesInfo_rules_domains.numberOfButtons', paginator.calculateArrayButtons(response.size, 1));
        }
      }).catch(() => {
        Ember.set(this, 'pagesInfo_rules_domains.numberOfButtons', null);
      });
      paginator.loadElements(rule.id, Ember.get(this, 'pageSize'), 'rules_domains', 'domain', 1).then(response => {
        Ember.set(this, 'domainsByRule', response);
      }).catch(() => {
        Ember.set(this, 'domainsByRule', {});
      });
    },

    loadPaginationPricelistsItems() {
      let priceList = Ember.get(this, 'selected.price-list');
      let paginator = Ember.get(this, 'paginator');
      let pageSize = Ember.get(this, 'paginationModelInfo_priceList_items.pageSize');
      let prefix = 'priceLists_items';
      let currentPage = Ember.get(this, 'paginationModelInfo_priceList_items.currentPage');
      Ember.set(this, 'itemsByPricelist', {});
      paginator.calculateTotalPages(priceList.id, pageSize, `${prefix}_totalPages`).then(response => {
        Ember.set(this, 'paginationModelInfo_priceList_items.dataSize', response.size);
      }).catch(() => {
        Ember.set(this, 'paginationModelInfo_priceList_items.dataSize', 0);
      });
      paginator.loadElements(priceList.id, pageSize, prefix, 'price-list', currentPage).then(response => {
        Ember.set(this, 'itemsByPricelist', response);
      }).catch(() => {
        Ember.set(this, 'itemsByPricelist', {});
      });
    },

    //Find an Item from a response of get-price-prodcode
    findItem(resp) {
      return this.get('store').findRecord('item', resp[0].item_id).then(function (record) {
        return record;
      });
    },

    //Set Item in selected.product and open edit popup
    async setFoundItem(resp) {
      if (resp.length > 0) {
        let record = await this.findItem(resp);
        Ember.set(this, 'selected.product', record);
        Ember.set(this, 'confirmPopUp', true);
      }
    },

    //Obtain first item of pricelist and prodcode
    findItemSimilar(code, pricelistId) {
      let url = `item/find-by-prodcode-pricelist-id?price_list_id=${pricelistId}&product_code=${code}`;
      Ember.get(this, 'store').queryRecord('item', {
        endpointURI: url
      }).then(record => {
        Ember.set(this, 'selected.product', record);
        Ember.set(this, 'confirmPopUp', true);
      });
    },

    import(file, params, callback) {
      let notifications = this.get('notifications');
      let token = this.get('session.data.authenticated.access_token');
      let formData = new FormData();
      formData.append('file', file);
      return (0, _fetch.default)(params.uri, {
        method: params.method,
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      }).then(response => {
        if (response.ok) {
          notifications.show('success', `${params.title} imported`);
          callback(this);
        } else {
          return response.json().then(data => {
            notifications.show('error', data.error.errors);
            callback(this);
          }).catch(error => {
            notifications.show('error', response.statusText);
            notifications.show('error', error);
            callback(this);
          });
        }
      }).catch(error => {
        notifications.show('error', error);
        callback();
      });
    },

    loadCategories(priceListId, entityId) {
      Ember.set(this, 'categories.pricelist', {});
      let categories = Ember.get(this, 'store').query('ref-domain-price-list', {
        endpointURI: `price-lists/${priceListId}/categories?entity_id=${entityId}`
      });
      Ember.set(this, 'categories.pricelist', categories);
    },

    actions: {
      open(entity, priceList) {
        this.reset('selected.price-list');
        this.reset('selected.item');
        this.reset('selected.domain');
        this.reset('selected.entity');
        this.reset('selected.product');
        this.reset('selected.rule');
        this.reset('selected.domain');
        Ember.set(this, 'selected', {});
        Ember.set(this, 'selected.entity', entity);
        Ember.set(this, 'selected.price-list-id', null);

        if (priceList) {
          this.create('selected.item', 'item');
          this.create('selected.domain', 'ref-domain-price-list');
          Ember.set(this, 'selected.price-list', priceList);
          Ember.set(this, 'selected.price-list-id', priceList.id);
          this.loadCategories(Ember.get(this, 'selected.price-list.id'), Ember.get(this, 'selected.entity.id'));
          this.loadPaginationPricelistsItems();
        } else {
          this.create('selected.product', 'product');
          this.create('selected.rule', 'rule');
          this.create('selected.domain', 'domain');
          this.loadPaginationProductsEntity();
        }
      },

      changePage(data, currentPage) {
        Ember.set(data, 'currentPage', currentPage);
        this.loadPaginationPricelistsItems();
      },

      newEntity() {
        let entity = Ember.get(this, 'store').createRecord('entity');
        this.send('open', entity, null);
      },

      newRule() {
        this.reset('selected.rule');
        this.create('selected.rule', 'rule');
      },

      newMasterList(entity) {
        let list = Ember.get(this, 'store').createRecord('priceList', {
          entity,
          typeTrx: 'F'
        });
        this.send('open', entity, list);
      },

      newRecord(model) {
        let priceList = Ember.get(this, 'store').createRecord('priceList', {
          entity: model.entity,
          typeTrx: model.typeTrx,
          typeList: 'F',
          currencyCode: model.currencyCode,
          datefrom: model.datefrom,
          commencementDate: model.commencementDate,
          dateto: model.dateto,
          isbyfactor: model.isbyfactor,
          description: model.description,
          discount: model.discount,
          parent: model
        });
        this.send('open', model.entity, priceList);
      },

      onSave(model) {
        let notifications = this.get('notifications');
        return model.save().then(() => {
          notifications.show('success', 'Saved Successfuly');
          window.location.reload(false);
        }).catch(error => {
          notifications.show('error', error);
        });
      },

      onDelete(model) {
        let modelName = Ember.get(model, '_internalModel.modelName');
        let notifications = this.get('notifications');
        model.destroyRecord().then(() => {
          notifications.show('success', 'Record deleted');

          if (modelName === 'product') {
            this.loadPaginationProductsEntity();
          }

          if (modelName === 'domain') {
            this.loadPaginationDomainsRules();
          }

          if (modelName === 'item') {
            this.loadPaginationPricelistsItems();
            Ember.set(this, 'pricePopUp', false);
            Ember.set(this, 'selected.product', null);
          }
        }).catch(error => {
          let message = undefined;

          try {
            message = error.errors.filter(error => error.message).map(error => error.message).reduce((error, acc) => acc += `, ${error}`);
          } finally {
            if (!message) {
              message = error;
            }

            notifications.show('error', message);
          }
        });
      },

      onEditPrice(priceList, item, product) {
        let notifications = this.get('notifications');
        return product.save().then(() => {
          notifications.show('success', 'Product price updated successfuly');
        }).catch(error => {
          notifications.show('error', error);
        });
      },

      onDeleteMain(model) {
        let notifications = this.get('notifications');
        model.destroyRecord().then(() => {
          Ember.set(this, 'selected', {});
          notifications.show('success', 'Deleted');
        }).catch(error => {
          notifications.show('error', error);
        });
      },

      importProducts(priceList, file) {
        this.import(file, {
          uri: `${_environment.default.APP.apiUrl}/price-lists/${priceList.id}/import`,
          method: 'PUT',
          title: 'Products'
        }, function (pagination) {
          if (pagination) {
            pagination.loadPaginationPricelistsItems();
          } else {
            priceList.hasMany('items').reload();
          }
        });
      },

      importPriceList(entity, file) {
        this.import(file, {
          uri: `${_environment.default.APP.apiUrl}/entities/${entity.id}/import-lists`,
          method: 'POST',
          title: 'Price List'
        }, function () {
          entity.hasMany('priceList').reload();
        });
      },

      importProductsEntity(entity, file) {
        this.import(file, {
          uri: `${_environment.default.APP.apiUrl}/import-products-entitiy?entity_id=${entity.id}`,
          method: 'PUT',
          title: 'Products Entity'
        }, function (pagination) {
          if (pagination) {
            pagination.loadPaginationProductsEntity();
          } else {
            entity.hasMany('products').reload();
          }
        });
      },

      importDomains(rule, file) {
        this.import(file, {
          uri: `${_environment.default.APP.apiUrl}/import-domains?rule_id=${rule.id}`,
          method: 'PUT',
          title: 'Domains'
        }, function (pagination) {
          if (pagination) {
            pagination.loadPaginationDomainsRules();
          } else {
            rule.hasMany('domains').reload();
          }
        });
      },

      openImport(state) {
        Ember.set(this, 'importPopUp', state);
      },

      forceOpenPriceEdit() {
        Ember.set(this, 'pricePopUp', true);
      },

      openPriceEdit(state, product) {
        Ember.set(this, 'pricePopUp', state);

        if (state) {
          Ember.set(this, 'selected.product', product);
        }
      },

      openConfirmPopUp(state) {
        Ember.set(this, 'confirmPopUp', state);
      },

      async configDomains(priceList, domain) {
        let parent = await Ember.get(this, 'store').findRecord('domain', domain.domain_id);
        Ember.set(domain, 'priceList', priceList);
        Ember.set(domain, 'domain', parent);
        domain.save().then(() => {
          let newDomain = Ember.get(this, 'store').createRecord('ref-domain-price-list', {});
          Ember.set(this, 'selected.domain', newDomain);
        });
      },

      saveItem(priceList, item) {
        let notifications = this.get('notifications');
        Ember.set(item, 'priceList', priceList);
        item.save().then(() => {
          let newItem = Ember.get(this, 'store').createRecord('item', {});
          Ember.set(this, 'selected.item', newItem);
          notifications.show('success', 'Item Saved');
          this.loadPaginationPricelistsItems();
        }).catch(error => {
          notifications.show('error', error);
        });
      },

      saveEntity(entity) {
        let notifications = this.get('notifications');
        entity.save().then(() => {
          let newEntity = Ember.get(this, 'store').createRecord('entity', {});
          Ember.set(this, 'selected.entity', newEntity);
          notifications.show('success', 'Entity Saved');
        }).catch(error => {
          notifications.show('error', error);
        });
      },

      saveProduct(entity, product) {
        let notifications = this.get('notifications');
        Ember.set(product, 'entity', entity);
        product.save().then(() => {
          let newProduct = Ember.get(this, 'store').createRecord('product', {});
          Ember.set(this, 'selected.product', newProduct);
          notifications.show('success', 'Product Saved');
          this.loadPaginationProductsEntity();
        }).catch(error => {
          notifications.show('error', error);
        });
      },

      saveRule(entity, rule) {
        let notifications = this.get('notifications');
        Ember.set(rule, 'entity', entity);
        rule.save().then(() => {
          let newRule = Ember.get(this, 'store').createRecord('rule', {});
          Ember.set(this, 'selected.rule', newRule);
          notifications.show('success', 'Rule Saved');
        }).catch(error => {
          notifications.show('error', error);
        });
      },

      saveDomain(rule, domain) {
        let notifications = this.get('notifications');
        Ember.set(domain, 'rule', rule);
        domain.save().then(() => {
          let newDomain = Ember.get(this, 'store').createRecord('domain', {});
          Ember.set(this, 'selected.domain', newDomain);
          notifications.show('success', 'Domain Saved');
          this.loadPaginationDomainsRules();
        }).catch(error => {
          notifications.show('error', error);
        });
      },

      selectRule(rule) {
        Ember.set(this, 'selected.rule', rule);
        this.loadPaginationDomainsRules();
      },

      findProducts(text) {
        Ember.set(this, 'products', null);

        if (text.length >= 3) {
          let products = Ember.get(this, 'store').query('product', {
            query: text
          });
          Ember.set(this, 'products', products);
        }
      },

      fillProduct(product, option) {
        Ember.set(product, "epc", Ember.get(option, "epc"));
        Ember.set(product, "productCode", Ember.get(option, "productCode"));
        Ember.set(product, "name", Ember.get(option, "name"));
        Ember.set(product, "uoms", Ember.get(option, "uoms"));
        Ember.set(product, "category", Ember.get(option, "category"));
        Ember.set(this, 'products', null);
      },

      findBPartner(text) {
        Ember.set(this, 'partners', null);

        if (text.length >= 3) {
          let partners = Ember.get(this, 'store').query('business-partner', {
            query: text
          });
          Ember.set(this, 'partners', partners);
        }
      },

      fillBPartner(partner, option) {
        Ember.set(partner, "name", Ember.get(option, "name"));
        Ember.set(partner, "value", Ember.get(option, "value"));
        Ember.set(this, 'partner', null);
      },

      findEntityProducts(entity, search) {
        Ember.set(this, 'entityProducts', null);

        if (search.length >= 3) {
          let products = Ember.get(this, 'store').query('product', {
            endpointURI: `entities/${entity.id}/search-products`,
            search
          });
          Ember.set(this, 'entityProducts', products);
        }
      },

      fillItem(model, product) {
        Ember.set(model, 'name', Ember.get(product, 'name'));
        Ember.set(model, 'product', product);
        this.findItemSimilar(Ember.get(product, 'productCode'), Ember.get(this, "selected.price-list").id);
      },

      findPriceLists(text) {
        let eParams = Ember.get(this, 'entities_params');
        Ember.set(this, 'price_lists_system.search', {});

        if (!Ember.isEmpty(eParams) && text.length >= 0) {
          let lists = Ember.get(this, 'store').query('price-list', {
            endpointURI: `price-lists?query=${text}${eParams}`
          });
          Ember.set(this, 'price_lists_system.search', lists);
        }
      },

      async openPriceList(_pastSelected, actualSelected) {
        let priceList = await actualSelected.parent;

        if (!priceList) {
          priceList = actualSelected;
        }

        priceList.entity.then(entity => this.send('open', entity, actualSelected));
      },

      findCategoriesSystem(text) {
        Ember.set(this, 'categories.system', {});
        let id = Ember.get(this, 'selected.entity.id');

        if (text.length >= 3) {
          let categories = Ember.get(this, 'store').query('domain', {
            endpointURI: `categories/search?query=${text}&entity=${id}`
          });
          Ember.set(this, 'categories.system', categories);
        }
      },

      saveCategory(_pastSelected, actualSelected) {
        Ember.set(this, 'categories.selected', actualSelected);
        let ajax = Ember.get(this, 'ajax');
        let notification = Ember.get(this, 'notifications');
        let hermes = Ember.get(this, 'hermes');
        let priceListId = Ember.get(this, "selected.price-list.id");
        let entityId = Ember.get(this, "selected.entity.id");
        ajax.request(`/api/ref-domain-price-lists`, {
          method: 'POST',
          data: {
            data: {
              attributes: {
                price_list_id: priceListId,
                domain_id: Ember.get(actualSelected, 'id')
              }
            }
          }
        }).then(() => {
          notification.show('success', hermes.t('msg.categorySaved'));
          this.loadCategories(priceListId, entityId);
        }).catch(() => {
          notification.show('error', hermes.t('msg.error.saveCategory'));
        });
      },

      downloadExcelPriceList() {
        let download = Ember.get(this, 'download');
        let id = Ember.get(this, 'selected.price-list.id');
        download.downloadFile(`/api/price-lists/${id}/download`, "price list.xlsx");
      }

    }
  });

  _exports.default = _default;
});