define("niffler/pods/components/widgets/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C/PYRUsj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,null],[1,1,0,0,\"\\n\"],[16,1,[[31,19,4,[27,[26,3,\"CallHead\"],[]],null,[[\"is-parent\",\"name\",\"item\"],[[27,[26,2,\"Expression\"],[]],[27,[26,1,\"Expression\"],[]],[31,72,9,[27,[26,0,\"CallHead\"],[]],[\"widgets/list/item\"],null]]]]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"name\",\"is-parent\",\"hash\"]}",
    "meta": {
      "moduleName": "niffler/pods/components/widgets/list/template.hbs"
    }
  });

  _exports.default = _default;
});