define("niffler/pods/components/forms/input-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WagHF6ts",
    "block": "{\"symbols\":[],\"statements\":[[9,\"input\",true],[13,\"class\",[32,[[31,0,0,[27,[26,3,\"CallHead\"],[]],[\"input\"],[[\"from\"],[\"niffler/pods/components/forms/input-checkbox/styles\"]]]]],null],[13,\"id\",[31,14,6,[27,[26,2,\"CallHead\"],[]],[[27,[26,4,\"Expression\"],[]],\"-checkbox\"],null],null],[13,\"checked\",[27,[26,6,\"AppendSingleId\"],[]],null],[13,\"onclick\",[31,112,6,[27,[26,8,\"CallHead\"],[]],[[27,[24,0],[]],[27,[26,7,\"Expression\"],[]]],[[\"value\"],[\"target.checked\"]]],null],[12,\"type\",\"checkbox\",null],[10],[11],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,1,\"BlockHead\"],[]],[[27,[26,5,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"label\",true],[13,\"class\",[32,[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[\"label \",[31,235,2,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]],\"required\"],null]],null]],[[\"from\"],[\"niffler/pods/components/forms/input-checkbox/styles\"]]]]],null],[13,\"for\",[31,184,6,[27,[26,2,\"CallHead\"],[]],[[27,[26,4,\"Expression\"],[]],\"-checkbox\"],null],null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[27,[26,5,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"required\",\"if\",\"concat\",\"local-class\",\"name\",\"label\",\"value\",\"onChange\",\"action\"]}",
    "meta": {
      "moduleName": "niffler/pods/components/forms/input-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});