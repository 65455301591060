define("niffler/pods/components/paginate/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "pagination": "_pagination_11lgh5",
    "carrousel": "_carrousel_11lgh5",
    "active": "_active_11lgh5",
    "navButtons": "_navButtons_11lgh5"
  };
  _exports.default = _default;
});