define("niffler/pods/components/forms/input-select/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "input": "_input_whlboy _input_158mqq",
    "label": "_label_whlboy _label_158mqq",
    "form-input": "_form-input_whlboy _form-input_158mqq",
    "required": "_required_whlboy _required_158mqq",
    "expand-eight": "_expand-eight_whlboy _expand-eight_158mqq",
    "expand-quarter": "_expand-quarter_whlboy _expand-quarter_158mqq",
    "expand-half": "_expand-half_whlboy _expand-half_158mqq",
    "expand-th-q": "_expand-th-q_whlboy _expand-th-q_158mqq",
    "expand-full": "_expand-full_whlboy _expand-full_158mqq",
    "input-error": "_input-error_whlboy _input-error_158mqq",
    "error-message": "_error-message_whlboy _error-message_158mqq"
  };
  _exports.default = _default;
});