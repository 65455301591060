define("niffler/pods/components/forms/input-checkbox/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "input": "_input_qvzt2h _input_158mqq",
    "label": "_label_qvzt2h _label_158mqq",
    "form-input": "_form-input_qvzt2h _form-input_158mqq",
    "required": "_required_qvzt2h _required_158mqq",
    "expand-eight": "_expand-eight_qvzt2h _expand-eight_158mqq",
    "expand-quarter": "_expand-quarter_qvzt2h _expand-quarter_158mqq",
    "expand-half": "_expand-half_qvzt2h _expand-half_158mqq",
    "expand-th-q": "_expand-th-q_qvzt2h _expand-th-q_158mqq",
    "expand-full": "_expand-full_qvzt2h _expand-full_158mqq"
  };
  _exports.default = _default;
});