define("niffler/pods/components/widgets/list/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "list-content": "_list-content_12dunu",
    "name": "_name_12dunu"
  };
  _exports.default = _default;
});