define("niffler/pods/components/forms/input-text/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "input": "_input_1sin3j _input_158mqq",
    "label": "_label_1sin3j _label_158mqq",
    "form-input": "_form-input_1sin3j _form-input_158mqq",
    "required": "_required_1sin3j _required_158mqq",
    "expand-eight": "_expand-eight_1sin3j _expand-eight_158mqq",
    "expand-quarter": "_expand-quarter_1sin3j _expand-quarter_158mqq",
    "expand-half": "_expand-half_1sin3j _expand-half_158mqq",
    "expand-th-q": "_expand-th-q_1sin3j _expand-th-q_158mqq",
    "expand-full": "_expand-full_1sin3j _expand-full_158mqq",
    "left-label": "_left-label_1sin3j _left-label_158mqq",
    "right-label": "_right-label_1sin3j _right-label_158mqq",
    "bottom-label": "_bottom-label_1sin3j _bottom-label_158mqq",
    "input-error": "_input-error_1sin3j _input-error_158mqq",
    "error-message": "_error-message_1sin3j _error-message_158mqq",
    "readonly": "_readonly_1sin3j _readonly_158mqq"
  };
  _exports.default = _default;
});