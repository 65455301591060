define("niffler/pods/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/6HA1eJG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"login-form\",[],[[\"@authenticate\"],[[31,27,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"authenticate\"],null]]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "niffler/pods/login/template.hbs"
    }
  });

  _exports.default = _default;
});