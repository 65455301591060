define("niffler/pods/components/panel/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "panel": "_panel_geu6t9",
    "panel-header": "_panel-header_geu6t9",
    "panel-title": "_panel-title_geu6t9"
  };
  _exports.default = _default;
});