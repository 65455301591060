define("niffler/pods/components/niffler/layout/grid/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "grid-layout": "_grid-layout_zp92ad",
    "margin-none": "_margin-none_zp92ad",
    "margin-small": "_margin-small_zp92ad",
    "margin-medium-small": "_margin-medium-small_zp92ad",
    "margin-header-cover": "_margin-header-cover_zp92ad"
  };
  _exports.default = _default;
});