define("niffler/pods/components/niffler/button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "button": "_button_1s3xhn",
    "ripple": "_ripple_1s3xhn",
    "color-primary": "_color-primary_1s3xhn",
    "color-secondary": "_color-secondary_1s3xhn",
    "color-gray": "_color-gray_1s3xhn",
    "color-cancel": "_color-cancel_1s3xhn",
    "color-pdf": "_color-pdf_1s3xhn",
    "color-excel": "_color-excel_1s3xhn",
    "design-default": "_design-default_1s3xhn",
    "design-ghost": "_design-ghost_1s3xhn",
    "design-simple": "_design-simple_1s3xhn",
    "design-breadcrumb": "_design-breadcrumb_1s3xhn",
    "design-cover": "_design-cover_1s3xhn",
    "size-menu": "_size-menu_1s3xhn",
    "size-square": "_size-square_1s3xhn",
    "size-tiny_small": "_size-tiny_small_1s3xhn",
    "size-small": "_size-small_1s3xhn",
    "size-normal": "_size-normal_1s3xhn",
    "size-big": "_size-big_1s3xhn",
    "size-full": "_size-full_1s3xhn"
  };
  _exports.default = _default;
});