define("niffler/pods/components/niffler/title/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dTUDE0bW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"div\",true],[13,\"class\",[32,[[31,0,0,[27,[26,0,\"CallHead\"],[]],[\"title-label\"],[[\"from\"],[\"niffler/pods/components/niffler/title/styles\"]]]]],null],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[27,[26,1,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"],[9,\"div\",true],[12,\"loca-class\",\"\",null],[10],[1,1,0,0,\"\\n  \"],[16,1,null],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"title\"]}",
    "meta": {
      "moduleName": "niffler/pods/components/niffler/title/template.hbs"
    }
  });

  _exports.default = _default;
});