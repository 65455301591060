define("niffler/pods/components/niffler/tree-item/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "tree": "_tree_q6wccm",
    "nested-tree": "_nested-tree_q6wccm",
    "tree-icon": "_tree-icon_q6wccm",
    "tree-label": "_tree-label_q6wccm",
    "tree-item": "_tree-item_q6wccm",
    "tree-selected": "_tree-selected_q6wccm",
    "tree-label-hover": "_tree-label-hover_q6wccm",
    "tree-dotted": "_tree-dotted_q6wccm"
  };
  _exports.default = _default;
});