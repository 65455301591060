define("niffler/pods/components/niffler/menu/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "menu": "_menu_54wmx7",
    "menu-mask": "_menu-mask_54wmx7",
    "menu-list": "_menu-list_54wmx7",
    "direction-left-bottom": "_direction-left-bottom_54wmx7",
    "direction-right-bottom": "_direction-right-bottom_54wmx7",
    "direction-left-top": "_direction-left-top_54wmx7",
    "direction-right-top": "_direction-right-top_54wmx7"
  };
  _exports.default = _default;
});