define("niffler/pods/components/login-form/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "login-form": "_login-form_qmj6s3",
    "login-body": "_login-body_qmj6s3",
    "brand": "_brand_qmj6s3"
  };
  _exports.default = _default;
});