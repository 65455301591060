define("niffler/pods/components/niffler/menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uNjYuOSt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"span\",true],[13,\"onclick\",[31,16,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"toggleMenu\"],null],null],[12,\"role\",\"button\",null],[10],[1,1,0,0,\"\\n  \"],[16,1,[[31,64,4,[27,[26,2,\"CallHead\"],[]],null,[[\"Button\"],[[31,77,9,[27,[26,4,\"CallHead\"],[]],[\"niffler/button\"],[[\"design\"],[[27,[26,3,\"Expression\"],[]]]]]]]]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,6,\"BlockHead\"],[]],[[27,[26,5,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"div\",true],[13,\"class\",[32,[[31,0,0,[27,[26,0,\"CallHead\"],[]],[\"menu-mask\"],[[\"from\"],[\"niffler/pods/components/niffler/menu/styles\"]]]]],null],[13,\"onclick\",[31,185,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"toggleMenu\"],null],null],[12,\"role\",\"button\",null],[10],[11],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[13,\"class\",[32,[[31,0,0,[27,[26,0,\"CallHead\"],[]],[\"menu-list\"],[[\"from\"],[\"niffler/pods/components/niffler/menu/styles\"]]]]],null],[10],[1,1,0,0,\"\\n    \"],[16,1,[[31,273,4,[27,[26,2,\"CallHead\"],[]],null,[[\"Item\"],[\"niffler/menu/item\"]]]]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"local-class\",\"action\",\"hash\",\"design\",\"component\",\"open\",\"if\"]}",
    "meta": {
      "moduleName": "niffler/pods/components/niffler/menu/template.hbs"
    }
  });

  _exports.default = _default;
});