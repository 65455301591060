define("niffler/pods/components/fragments/entity/general-info/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vyt06v7L",
    "block": "{\"symbols\":[\"@entity\"],\"statements\":[[7,\"layout\",[],[[\"@direction\"],[\"row\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n  \"],[7,\"forms/input-text\",[],[[\"@value\",\"@errors\",\"@placeholder\",\"@label\",\"@width\",\"@onChange\"],[[27,[24,1],[\"name\"]],[27,[24,1],[\"errors\",\"name\"]],[31,130,8,[27,[26,0,\"CallHead\"],[]],[\"msj.nombreSocio\"],null],[31,172,8,[27,[26,0,\"CallHead\"],[]],[\"pedidoAuto.name\"],null],\"3\",[31,230,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[31,238,3,[27,[26,1,\"CallHead\"],[]],[[27,[24,1],[\"name\"]]],null]],null]]],null],[1,1,0,0,\"\\n\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"localize\",\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "niffler/pods/components/fragments/entity/general-info/form/template.hbs"
    }
  });

  _exports.default = _default;
});