define("niffler/adapters/application", ["exports", "ember-data/adapters/json-api", "ember-simple-auth/mixins/data-adapter-mixin", "niffler/config/environment"], function (_exports, _jsonApi, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend(_dataAdapterMixin.default, {
    host: _environment.default.APP.baseUrl,
    namespace: _environment.default.APP.basePath,
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let headers = {
        'Content-Type': 'application/vnd.api+json'
      };

      if (this.get('session.isAuthenticated')) {
        headers.Authorization = `Bearer ${this.get('session.data.authenticated.access_token')}`;
      }

      return headers;
    }),

    urlForQueryRecord(query) {
      if (query.findBy) {
        let url = `${this._super(...arguments)}/${query.findBy}`;
        delete query.findBy;
        return url;
      }

      if (query.endpointURI) {
        let url = `${this.host}/${this.namespace}/${query.endpointURI}`;
        delete query.endpointURI;
        return url;
      }

      return this._super(...arguments);
    },

    urlForQuery(query) {
      if (query.findBy) {
        let url = `${this._super(...arguments)}/${query.findBy}`;
        delete query.findBy;
        return url;
      }

      if (query.endpointURI) {
        let url = `${this.host}/${this.namespace}/${query.endpointURI}`;
        delete query.endpointURI;
        return url;
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});