define("niffler/pods/components/niffler/layout/grid/row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['grid-row'],
    localClassNameBindings: ['rowSize', 'rowStyle', 'marginLayout'],
    size: 1,
    style: 'default',
    margin: 'none',
    marginLayout: Ember.computed('margin', function () {
      return `margin-${Ember.get(this, 'margin')}`;
    }),
    rowSize: Ember.computed('size', function () {
      return `col-${Ember.get(this, 'size')}`;
    }),
    rowStyle: Ember.computed('style', function () {
      return `style-${Ember.get(this, 'style')}`;
    })
  });

  _exports.default = _default;
});