define("niffler/pods/components/widgets/tree-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['tree-item'],
    classNameBindings: ['open:open', 'main:main'],
    open: false,
    main: false,
    actions: {
      toggle() {
        Ember.set(this, 'open', !Ember.get(this, 'open'));
      }

    }
  });

  _exports.default = _default;
});