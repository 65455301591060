define("niffler/models/item", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    epc: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    productCode: (0, _attr.default)('string'),
    prices: (0, _attr.default)('prices'),
    discount: (0, _attr.default)('number'),
    priceList: (0, _relationships.belongsTo)('price-list'),
    product: (0, _relationships.belongsTo)('product'),
    insertedAt: (0, _attr.default)('date2'),
    createdBy: (0, _attr.default)('string'),
    updatedAt: (0, _attr.default)('date2'),
    updatedBy: (0, _attr.default)('string')
  });

  _exports.default = _default;
});