define("niffler/pods/authorized/home/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "title": "_title_5ocke7",
    "home-panel": "_home-panel_5ocke7",
    "home-content": "_home-content_5ocke7",
    "new-item": "_new-item_5ocke7",
    "background": "_background_5ocke7"
  };
  _exports.default = _default;
});