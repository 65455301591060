define("niffler/pods/authorized/price_list/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    download: Ember.inject.service('file-downloader'),
    selected: {},
    pageSize: 15,
    totalPages: 0,
    entities_params: Ember.computed('model.@each.id', function () {
      let entities = Ember.get(this, 'model');
      return entities.reduce((acc, entity) => {
        return acc += `&entity_id[]=${entity.id}`;
      }, '');
    }),
    price_lists_system: {},
    price_list: {},
    items: [],

    async getItems(pageNumber, pageSize) {
      let text = Ember.get(this, 'filterText');
      let priceList = Ember.get(this, 'price_list');
      let query = '';

      if (text) {
        query = `query=${text}&`;
      }

      let lists = await Ember.get(this, 'store').query('item', {
        endpointURI: `price-lists/${priceList.id}/items?${query}page_size=${pageSize}&page_number=${pageNumber}`
      });
      Ember.set(this, 'items', lists);
    },

    async getPages() {
      let text = Ember.get(this, 'filterText');
      let priceList = Ember.get(this, 'price_list');
      let query = '';

      if (text) {
        query += `query=${text}&`;
      }

      let pageTotal = await Ember.get(this, 'store').queryRecord('page-total', {
        endpointURI: `price-lists/${priceList.id}/items/page-total?${query}page_size=${Ember.get(this, 'pageSize')}`
      });
      Ember.set(this, 'totalPages', pageTotal.size);
    },

    refresh(pageNumber, pageSize) {
      Ember.set(this, 'pageSize', pageSize);
      Ember.set(this, 'currentPage', pageNumber);
      this.getPages();
      this.getItems(pageNumber, pageSize);
    },

    actions: {
      selectedPriceList(_pastSelected, actualSelected) {
        if (actualSelected) {
          Ember.set(this, 'price_list', actualSelected);
          Ember.set(this, 'price_lists_system.value', actualSelected.name);
          this.refresh(1, Ember.get(this, 'pageSize'));
        }
      },

      findPriceLists(text) {
        let eParams = Ember.get(this, 'entities_params');
        Ember.set(this, 'price_lists_system.search', {});

        if (!Ember.isEmpty(eParams) && text.length >= 0) {
          let lists = Ember.get(this, 'store').query('price-list', {
            endpointURI: `price-lists?query=${text}${eParams}`
          });
          Ember.set(this, 'price_lists_system.search', lists);
        }
      },

      filterItems(text) {
        Ember.set(this, 'filterText', text);
        this.refresh(1, Ember.get(this, 'pageSize'));
      },

      changePage(pageNumber, perPage) {
        this.refresh(pageNumber, perPage);
      },

      exportReport() {
        let download = Ember.get(this, 'download');
        let priceListId = Ember.get(this, 'price_list.id');
        let text = Ember.get(this, 'filterText');
        let query = '';

        if (text) {
          query = `?query=${text}`;
        }

        download.downloadFile(`/api/price-lists/${priceListId}/items/_export${query}`, 'price list report.xlsx');
      }

    }
  });

  _exports.default = _default;
});