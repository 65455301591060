define("niffler/pods/components/panel/toolbar-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PrABZ8jZ",
    "block": "{\"symbols\":[\"@icon\"],\"statements\":[[7,\"fa-icon\",[],[[\"@icon\"],[[27,[24,1],[]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "niffler/pods/components/panel/toolbar-button/template.hbs"
    }
  });

  _exports.default = _default;
});