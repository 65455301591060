define("niffler/pods/components/login-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IkESsnfO",
    "block": "{\"symbols\":[],\"statements\":[[1,1,0,0,\"\\n\"],[9,\"div\",true],[13,\"class\",[32,[[31,0,0,[27,[26,0,\"CallHead\"],[]],[\"login-body\"],[[\"from\"],[\"niffler/pods/components/login-form/styles\"]]]]],null],[10],[1,1,0,0,\"\\n  \"],[9,\"h2\",true],[13,\"class\",[32,[[31,0,0,[27,[26,0,\"CallHead\"],[]],[\"brand\"],[[\"from\"],[\"niffler/pods/components/login-form/styles\"]]]]],null],[10],[1,1,0,0,\"\\n    Niffler\\n  \"],[11],[1,1,0,0,\"\\n  \"],[7,\"forms/input-text\",[],[[\"@placeholder\",\"@value\",\"@onChange\",\"@label\",\"@width\"],[\"Enter Login\",[27,[26,1,\"AppendSingleId\"],[]],[31,167,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],[31,175,3,[27,[26,2,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[]]],null]],null],\"Login\",\"5\"]],null],[1,1,0,0,\"\\n\\n  \"],[7,\"forms/input-text\",[],[[\"@label\",\"@placeholder\",\"@onChange\",\"@type\",\"@width\",\"@value\"],[\"Password\",\"Enter Password\",[31,318,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],[31,326,3,[27,[26,2,\"CallHead\"],[]],[[27,[26,4,\"Expression\"],[]]],null]],null],\"password\",\"5\",[27,[26,4,\"AppendSingleId\"],[]]]],null],[1,1,0,0,\"\\n    \"],[9,\"span\",true],[10],[1,1,0,0,\" \"],[9,\"hr\",true],[10],[11],[1,1,0,0,\" \"],[11],[1,1,0,0,\"\\n  \"],[7,\"widgets/x-button\",[],[[\"@color\",\"@data-test-id\",\"@action\"],[\"send\",\"login-submit\",[31,514,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],[27,[26,5,\"Expression\"],[]],[27,[26,1,\"Expression\"],[]],[27,[26,4,\"Expression\"],[]]],null]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n    Login\\n  \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"email\",\"mut\",\"action\",\"password\",\"authenticate\"]}",
    "meta": {
      "moduleName": "niffler/pods/components/login-form/template.hbs"
    }
  });

  _exports.default = _default;
});