define("niffler/models/price-list", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    currencyCode: (0, _attr.default)('string'),
    datefrom: (0, _attr.default)('string'),
    commencementDate: (0, _attr.default)('string'),
    dateto: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    isbyfactor: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    typeList: (0, _attr.default)('string'),
    typeTrx: (0, _attr.default)('string'),
    discount: (0, _attr.default)('number'),
    entity: (0, _relationships.belongsTo)('entity'),
    domains: (0, _relationships.hasMany)('ref-domain-price-list'),
    items: (0, _relationships.hasMany)('item'),
    children: (0, _relationships.hasMany)('price-lists', {
      inverse: "parent"
    }),
    parent: (0, _relationships.belongsTo)('price_list', {
      inverse: "children"
    }),
    disabledAt: (0, _attr.default)('string'),
    createdBy: (0, _attr.default)('string'),
    updatedBy: (0, _attr.default)('string'),
    areasBps: (0, _relationships.hasMany)('ref-domain-price-list', {
      inverse: 'priceList'
    }),
    currencyData: Ember.computed('currencyCode', {
      get() {
        switch (Ember.get(this, 'currencyCode')) {
          case 'SAR':
            return {
              separator: ".",
              prefix: "",
              suffix: " SAR "
            };

          default:
            return {
              separator: ".",
              prefix: " $ ",
              suffix: ""
            };
        }
      }

    }),
    user: Ember.computed('user', {
      get() {
        let createdby = Ember.get(this, 'createdBy');
        let updatedby = Ember.get(this, 'updatedBy');

        if (updatedby) {
          return updatedby;
        } else {
          return createdby;
        }
      }

    })
  });

  _exports.default = _default;
});