define("niffler/services/i18n", ["exports", "niffler/config/environment", "fetch"], function (_exports, _environment, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    language: null,
    labels: {},

    async load(language) {
      if (!language) {
        language = "en";
      }

      if (language !== Ember.get(this, 'language')) {
        let labels = await (0, _fetch.default)(`${_environment.default.APP.baseUrl}/api/labels?language=${language}`).then(response => {
          return response.json();
        });
        Ember.set(this, 'language', language);
        Ember.set(this, 'labels', labels);
      }
    },

    t(key, ...params) {
      let value = null;
      let labels = Ember.get(this, 'labels');

      if (labels) {
        value = labels[key];
      }

      if (!value) {
        value = key;
      }

      if (value && params) {
        value = value.replace(/{(\d+)}/g, function (match, number) {
          return typeof params[number] !== 'undefined' ? params[number] : match;
        });
      }

      return value;
    }

  });

  _exports.default = _default;
});