define("niffler/pods/components/niffler/panel/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "panel": "_panel_x6nv72",
    "panel-header": "_panel-header_x6nv72",
    "panel-title": "_panel-title_x6nv72",
    "size-fill": "_size-fill_x6nv72",
    "panel-body": "_panel-body_x6nv72",
    "margin-none": "_margin-none_x6nv72",
    "margin-small": "_margin-small_x6nv72",
    "color-warning": "_color-warning_x6nv72"
  };
  _exports.default = _default;
});