define("niffler/pods/components/paginate/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    paginator: Ember.inject.service('paginator'),
    activePage: 'page-1',
    currentPage: 1,
    numberOfButtons: null,
    data: null,
    modelInfo: {},
    pagesInfo: {},
    actions: {
      previousPage() {
        let paginator = Ember.get(this, 'paginator');
        let info = Ember.get(this, 'modelInfo');
        let page = Ember.get(this, "pagesInfo.currentPage");
        let currentlimit = Ember.get(this, "pagesInfo.currentUpperLimit");
        let index = Ember.get(this, 'pagesInfo.paginationIndex');
        let size = Ember.get(this, 'pagesInfo.numberOfButtonsDefault');

        if (page > 1) {
          page = page - 1;

          if (page === currentlimit - (size - 1) && page > 1) {
            currentlimit = currentlimit - 1;
            index = index - 1;
            Ember.set(this, 'pagesInfo.numberOfButtons', paginator.calculateArrayButtons(size, index));
            Ember.set(this, 'pagesInfo.paginationIndex', index);
          }
        }

        Ember.set(this, "pagesInfo.currentPage", page);
        Ember.set(this, 'pagesInfo.activePage', `page-${page}`);
        Ember.set(this, 'pagesInfo.currentUpperLimit', currentlimit);
        paginator.loadElements(info.id, info.size, info.type, info.model, page).then(response => {
          Ember.set(this, 'data', response);
        });
      },

      nextPage() {
        let info = Ember.get(this, 'modelInfo');
        let paginator = Ember.get(this, 'paginator');
        let page = Ember.get(this, 'pagesInfo.currentPage');
        let currentlimit = Ember.get(this, "pagesInfo.currentUpperLimit");
        let limitPage = Ember.get(this, "pagesInfo.limitPage");
        let size = Ember.get(this, 'pagesInfo.numberOfButtonsDefault');
        let index = Ember.get(this, 'pagesInfo.paginationIndex');

        if (page < limitPage) {
          page = page + 1;

          if (page === currentlimit && page < limitPage) {
            currentlimit = currentlimit + 1;
            index = index + 1;
            Ember.set(this, 'pagesInfo.numberOfButtons', paginator.calculateArrayButtons(size, index));
            Ember.set(this, 'pagesInfo.paginationIndex', index);
          }
        }

        Ember.set(this, "pagesInfo.currentPage", page);
        Ember.set(this, 'pagesInfo.currentUpperLimit', currentlimit);
        Ember.set(this, 'pagesInfo.activePage', `page-${page}`);
        paginator.loadElements(info.id, info.size, info.type, info.model, page).then(response => {
          Ember.set(this, 'data', response);
        });
      },

      goToPage(number) {
        let info = Ember.get(this, 'modelInfo');
        let paginator = Ember.get(this, 'paginator');
        let currentlimit = Ember.get(this, "pagesInfo.currentUpperLimit");
        let limitPage = Ember.get(this, "pagesInfo.limitPage");
        let size = Ember.get(this, 'pagesInfo.numberOfButtonsDefault');
        let index = Ember.get(this, 'pagesInfo.paginationIndex'); //next page

        if (number === currentlimit && number < limitPage) {
          currentlimit = currentlimit + 1;
          index = index + 1;
          Ember.set(this, 'pagesInfo.numberOfButtons', paginator.calculateArrayButtons(size, index));
          Ember.set(this, 'pagesInfo.paginationIndex', index);
        } //previous page


        if (number > 1 && number === currentlimit - (size - 1)) {
          currentlimit = currentlimit - 1;
          index = index - 1;
          Ember.set(this, 'pagesInfo.numberOfButtons', paginator.calculateArrayButtons(size, index));
          Ember.set(this, 'pagesInfo.paginationIndex', index);
        }

        Ember.set(this, "pagesInfo.currentPage", number);
        Ember.set(this, 'pagesInfo.activePage', `page-${number}`);
        Ember.set(this, 'pagesInfo.currentUpperLimit', currentlimit);
        paginator.loadElements(info.id, info.size, info.type, info.model, number).then(response => {
          Ember.set(this, 'data', response);
        });
      }

    }
  });

  _exports.default = _default;
});