define("niffler/pods/components/widgets/tree-item/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "tree-item": "_tree-item_6odo85",
    "tree-item-button": "_tree-item-button_6odo85",
    "tree-item-content": "_tree-item-content_6odo85"
  };
  _exports.default = _default;
});