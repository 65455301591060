define("niffler/pods/components/forms/input-upload/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['form-input', 'input-text'],
    localClassNameBindings: ['widthClass', 'labelPosition'],
    placeholder: '',
    width: 5,
    file: null,
    widthClass: Ember.computed('width', function () {
      switch (Ember.get(this, 'width')) {
        case 1:
          return 'expand-eight';

        case 2:
          return 'expand-quarter';

        case 3:
          return 'expand-half';

        case 4:
          return 'expand-th-q';

        case 5:
          return 'expand-full';

        default:
          return 'expand-full';
      }
    }),
    labelPosition: Ember.computed('label-align', function () {
      return Ember.get(this, 'label-align') + '-label';
    }),
    fileSize: Ember.computed('file', function () {
      let file = Ember.get(this, 'file');
      let bytes = file ? file.size : 0;
      let sizes = ['bytes', 'KB', 'MB', 'GB', 'TB'];

      if (bytes === 0) {
        return '0 bytes';
      } else {
        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
      }
    }),
    actions: {
      change(event) {
        let file = event.target.files[0];
        let onChange = Ember.get(this, 'onChange');
        Ember.set(this, 'file', file);

        if (onChange) {
          onChange(file, event);
        }
      }

    }
  });

  _exports.default = _default;
});