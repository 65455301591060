define("niffler/router", ["exports", "niffler/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    // Unauthorized routes
    this.route('login'); // Authorized routes

    this.route('authorized', {
      path: '/'
    }, function () {
      this.route('home', {
        path: '/'
      });
      this.route('price_list', {
        path: '/price-list'
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});