define("niffler/models/entity", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    udmConfig: (0, _attr.default)('map'),
    searchTree: (0, _attr.default)('map'),
    priceList: (0, _relationships.hasMany)('price-list'),
    products: (0, _relationships.hasMany)('product'),
    rules: (0, _relationships.hasMany)('rule')
  });

  _exports.default = _default;
});