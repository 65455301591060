define("niffler/pods/components/fragments/entity/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "title": "_title_2trr70"
  };
  _exports.default = _default;
});