define("niffler/pods/components/fragments/price-list/categories/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FfLd1trP",
    "block": "{\"symbols\":[\"category\",\"@readOnly\",\"@categories\",\"@fillCategory\",\"@findCategoriesSystem\"],\"statements\":[[7,\"layout\",[],[[\"@direction\"],[\"row\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[7,\"forms/input-search-box\",[],[[\"@readonly\",\"@label\",\"@oninput\",\"@design\",\"@width\",\"@model\",\"@items\",\"@onSelect\",\"@onChange\"],[[27,[24,2],[]],[31,102,8,[27,[26,0,\"CallHead\"],[]],[\"msg.searchCategories\"],null],\"false\",\"default\",\"3\",[27,[24,3],[\"selected\"]],[27,[24,3],[\"system\"]],[31,300,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],[27,[24,4],[]]],null],[31,343,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],[27,[24,5],[]]],null]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n        \"],[9,\"span\",true],[10],[1,0,0,0,[27,[24,1],[\"name\"]]],[11],[1,1,0,0,\" - \"],[9,\"span\",true],[10],[1,0,0,0,[27,[24,1],[\"discount\"]]],[1,1,0,0,\"%\"],[11],[1,1,0,0,\"\\n    \"]],\"parameters\":[1]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"localize\",\"action\"]}",
    "meta": {
      "moduleName": "niffler/pods/components/fragments/price-list/categories/form/template.hbs"
    }
  });

  _exports.default = _default;
});