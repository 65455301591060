define("niffler/pods/components/fragments/price-list/general-info/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "price-list-panel": "_price-list-panel_17lbmj",
    "title": "_title_17lbmj",
    "center-content": "_center-content_17lbmj"
  };
  _exports.default = _default;
});