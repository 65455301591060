define("niffler/transforms/prices", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    serialize(value) {
      if (value.length <= 0) {
        return null;
      }

      return value.reduce((prices, key) => {
        let price = {};
        price.price = key.price;
        price[key.udm] = key.name;
        prices[key.udm] = price;
        return prices;
      }, {});
    },

    deserialize(value) {
      let prices = null;

      for (let key in value) {
        if (!prices) {
          prices = [];
        }

        prices.pushObject({
          udm: key,
          price: value[key].price,
          name: value[key][key]
        });
      }

      return prices;
    }

  });

  _exports.default = _default;
});