define("niffler/pods/components/notification-mask/notification/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['notification'],
    localClassNameBindings: ['typeClass'],
    type: 'info',
    typeClass: Ember.computed('type', function () {
      return Ember.get(this, 'type');
    })
  });

  _exports.default = _default;
});