define("niffler/pods/components/panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3rkXrB56",
    "block": "{\"symbols\":[\"@title\",\"&default\"],\"statements\":[[9,\"div\",true],[13,\"class\",[32,[[31,0,0,[27,[26,0,\"CallHead\"],[]],[\"panel\"],[[\"from\"],[\"niffler/pods/components/panel/styles\"]]]]],null],[10],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[13,\"class\",[32,[[31,0,0,[27,[26,0,\"CallHead\"],[]],[\"panel-header\"],[[\"from\"],[\"niffler/pods/components/panel/styles\"]]]]],null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[13,\"class\",[32,[[31,0,0,[27,[26,0,\"CallHead\"],[]],[\"panel-title\"],[[\"from\"],[\"niffler/pods/components/panel/styles\"]]]]],null],[10],[1,1,0,0,\"\\n      \"],[1,0,0,0,[27,[24,1],[]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[13,\"class\",[32,[[31,0,0,[27,[26,0,\"CallHead\"],[]],[\"panel-toolbar\"],[[\"from\"],[\"niffler/pods/components/panel/styles\"]]]]],null],[10],[1,1,0,0,\"\\n      \"],[16,2,[[31,178,4,[27,[26,2,\"CallHead\"],[]],null,[[\"toolbar\"],[[31,192,9,[27,[26,1,\"CallHead\"],[]],[\"blank-template\"],null]]]]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[13,\"class\",[32,[[31,0,0,[27,[26,0,\"CallHead\"],[]],[\"panel-body\"],[[\"from\"],[\"niffler/pods/components/panel/styles\"]]]]],null],[10],[1,1,0,0,\"\\n    \"],[16,2,[[31,289,4,[27,[26,2,\"CallHead\"],[]],null,[[\"body\"],[[31,300,9,[27,[26,1,\"CallHead\"],[]],[\"blank-template\"],null]]]]]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "niffler/pods/components/panel/template.hbs"
    }
  });

  _exports.default = _default;
});