define("niffler/pods/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zfIEAMoC",
    "block": "{\"symbols\":[],\"statements\":[[9,\"img\",true],[13,\"class\",[32,[[31,0,0,[27,[26,0,\"CallHead\"],[]],[\"loading\"],[[\"from\"],[\"niffler/pods/loading/styles\"]]]]],null],[12,\"src\",\"/assets/loading.gif\",null],[12,\"alt\",\"loading\",null],[10],[11]],\"hasEval\":false,\"upvars\":[\"local-class\"]}",
    "meta": {
      "moduleName": "niffler/pods/loading/template.hbs"
    }
  });

  _exports.default = _default;
});