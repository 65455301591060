define("niffler/pods/components/fragments/dialogs/confirm/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    file: null,
    actions: {
      onAccept() {
        let onAccept = Ember.get(this, 'onAccept');

        if (onAccept) {
          onAccept(); //Non recursive

          this.send('onClose');
        }
      },

      onClose() {
        let onClose = Ember.get(this, 'onClose');

        if (onClose) {
          onClose(false);
        }
      }

    }
  });

  _exports.default = _default;
});