define("niffler/pods/components/popup/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "popup-mask": "_popup-mask_78kg4h",
    "close": "_close_78kg4h",
    "popup": "_popup_78kg4h",
    "popup-header": "_popup-header_78kg4h",
    "popup-close": "_popup-close_78kg4h",
    "popup-content": "_popup-content_78kg4h",
    "quarter3": "_quarter3_78kg4h"
  };
  _exports.default = _default;
});