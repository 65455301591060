define("niffler/pods/components/layout/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "layout": "_layout_8mvoxd",
    "direction-column": "_direction-column_8mvoxd",
    "direction-row": "_direction-row_8mvoxd",
    "direction-row-wrap": "_direction-row-wrap_8mvoxd",
    "content-space-between": "_content-space-between_8mvoxd",
    "content-right-align": "_content-right-align_8mvoxd",
    "content-space-around": "_content-space-around_8mvoxd",
    "margin-small": "_margin-small_8mvoxd",
    "margin-medium": "_margin-medium_8mvoxd",
    "margin-big": "_margin-big_8mvoxd",
    "expand-eight": "_expand-eight_8mvoxd",
    "expand-quarter": "_expand-quarter_8mvoxd",
    "expand-half": "_expand-half_8mvoxd",
    "expand-th-q": "_expand-th-q_8mvoxd",
    "expand-full": "_expand-full_8mvoxd"
  };
  _exports.default = _default;
});