define("niffler/pods/components/niffler/button/component", ["exports", "niffler/pods/components/niffler/button/styles"], function (_exports, _styles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['button'],
    classNameBindings: ['colorClass', 'sizeClass', 'designClass'],
    attributeBindings: ['disabled:disabled'],
    tagName: 'button',
    size: 'normal',
    color: 'primary',
    design: 'default',
    colorClass: Ember.computed('color', function () {
      return _styles.default[`color-${Ember.get(this, 'color')}`];
    }),
    designClass: Ember.computed('design', function () {
      return _styles.default[`design-${Ember.get(this, 'design')}`];
    }),
    sizeClass: Ember.computed('size', function () {
      return _styles.default[`size-${Ember.get(this, 'size')}`];
    }),

    click() {
      let action = Ember.get(this, 'action');

      if (action) {
        return action();
      }

      return false;
    }

  });

  _exports.default = _default;
});