define("niffler/pods/components/forms/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8WjfKpZN",
    "block": "{\"symbols\":[\"queue\",\"@uploadName\",\"@uploadAccept\",\"@uploadAction\"],\"statements\":[[7,\"file-upload\",[],[[\"@name\",\"@accept\",\"@onfileadd\"],[[27,[24,2],[]],[27,[24,3],[]],[27,[24,4],[]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n  \"],[9,\"a\",true],[13,\"class\",[32,[[31,0,0,[27,[26,4,\"CallHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[\"button \",[27,[26,2,\"Expression\"],[]]],null]],[[\"from\"],[\"niffler/pods/components/forms/file-upload/styles\"]]]]],null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,5,\"BlockHead\"],[]],[[27,[24,1],[\"files\",\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"      \"],[1,0,0,0,[27,[26,1,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"      \"],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"text\",\"loadingText\",\"colorClass\",\"concat\",\"local-class\",\"if\"]}",
    "meta": {
      "moduleName": "niffler/pods/components/forms/file-upload/template.hbs"
    }
  });

  _exports.default = _default;
});