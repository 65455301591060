define("niffler/pods/components/fragments/price-list/products/price/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      onChange(event) {
        let importProducts = Ember.get(this, 'importProducts');

        if (importProducts) {
          importProducts(event.target.files[0]);
        }
      }

    }
  });

  _exports.default = _default;
});