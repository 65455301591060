define("niffler/services/file-downloader", ["exports", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_fileSaver.default, {
    ajax: Ember.inject.service('niffler'),

    downloadFile(url, name) {
      let ajax = Ember.get(this, 'ajax');
      ajax.request(url, {
        dataType: 'blob',
        processData: false
      }).then(resp => {
        this.saveFileAs(name, resp, Ember.get(resp, 'type'));
      });
    }

  });

  _exports.default = _default;
});