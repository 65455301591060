define("niffler/pods/components/niffler/layout/grid/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['grid-layout'],
    localClassNameBindings: ['marginLayout', 'panelSize'],
    margin: 'none',
    marginLayout: Ember.computed('margin', function () {
      return `margin-${Ember.get(this, 'margin')}`;
    })
  });

  _exports.default = _default;
});