define("niffler/pods/components/fragments/dialogs/import/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    file: null,
    actions: {
      onImport() {
        let onAccept = Ember.get(this, 'onAccept');
        let file = Ember.get(this, 'file');

        if (onAccept && file) {
          onAccept(file);
          this.send('onClose');
        }
      },

      onClose() {
        let onClose = Ember.get(this, 'onClose');

        if (onClose) {
          Ember.set(this, 'file', null);
          onClose(false);
        }
      }

    }
  });

  _exports.default = _default;
});