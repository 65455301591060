define("niffler/pods/components/forms/input-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['form-input', 'input-select'],
    localClassNameBindings: ['widthClass'],
    width: 5,
    textKey: 'text',
    valueKey: 'value',
    object: false,
    isReadOnly: Ember.computed('readonly', 'disabled', function () {
      let readonly = Ember.get(this, 'readonly');
      let disabled = Ember.get(this, 'disabled');
      return readonly || disabled;
    }),
    widthClass: Ember.computed('width', function () {
      switch (Ember.get(this, 'width')) {
        case 1:
          return 'expand-eight';

        case 2:
          return 'expand-quarter';

        case 3:
          return 'expand-half';

        case 4:
          return 'expand-th-q';

        case 5:
          return 'expand-full';

        default:
          return 'expand-full';
      }
    }),

    change(event) {
      let value = event.target.value;
      let data = Ember.get(this, 'data');
      let object = Ember.get(this, 'object');
      let valueKey = Ember.get(this, 'valueKey');

      if (object) {
        value = data.find(d => {
          return d[valueKey] === value;
        });
      }

      return this.onChange(value);
    }

  });

  _exports.default = _default;
});