define("niffler/pods/components/widgets/table/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "table": "_table_6el86d",
    "simple": "_simple_6el86d",
    "box": "_box_6el86d",
    "narrow": "_narrow_6el86d"
  };
  _exports.default = _default;
});