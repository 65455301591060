define("niffler/pods/components/fragments/entity/rules/render-rule/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5D1uTbC8",
    "block": "{\"symbols\":[\"rule\",\"@rule\",\"@onSelectRule\",\"@rules\"],\"statements\":[[9,\"ul\",true],[12,\"class\",\"list-unstyled fw-normal pb-1\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,6,\"BlockHead\"],[]],[[31,0,0,[27,[26,5,\"CallHead\"],[]],[[31,0,0,[27,[26,5,\"CallHead\"],[]],[[27,[24,4],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"li\",true],[13,\"class\",[32,[\"mb-1 \",[31,0,0,[27,[26,3,\"CallHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,107,2,[27,[26,1,\"CallHead\"],[]],[[31,111,2,[27,[26,0,\"CallHead\"],[]],[[27,[24,2],[\"id\"]],[27,[24,1],[\"id\"]]],null],\"active\"],null],\" btn-toggle-style\"],null]],[[\"from\"],[\"niffler/pods/components/fragments/entity/rules/render-rule/styles\"]]]]],null],[13,\"onClick\",[31,171,6,[27,[26,4,\"CallHead\"],[]],[[27,[24,0],[]],[27,[24,3],[]],[27,[24,1],[]]],null],null],[10],[1,1,0,0,\"\\n      \"],[1,0,0,0,[27,[24,1],[\"name\"]]],[1,1,0,0,\" [\"],[1,0,0,0,[27,[24,1],[\"priority\"]]],[1,1,0,0,\"]\\n    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"concat\",\"local-class\",\"action\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "niffler/pods/components/fragments/entity/rules/render-rule/template.hbs"
    }
  });

  _exports.default = _default;
});