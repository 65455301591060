define("niffler/pods/components/forms/input-select-tree/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "input": "_input_20d8c9 _input_158mqq",
    "label": "_label_20d8c9 _label_158mqq",
    "form-input": "_form-input_20d8c9 _form-input_158mqq",
    "required": "_required_20d8c9 _required_158mqq",
    "expand-eight": "_expand-eight_20d8c9 _expand-eight_158mqq",
    "expand-quarter": "_expand-quarter_20d8c9 _expand-quarter_158mqq",
    "expand-half": "_expand-half_20d8c9 _expand-half_158mqq",
    "expand-th-q": "_expand-th-q_20d8c9 _expand-th-q_158mqq",
    "expand-full": "_expand-full_20d8c9 _expand-full_158mqq",
    "input-error": "_input-error_20d8c9 _input-error_158mqq",
    "error-message": "_error-message_20d8c9 _error-message_158mqq"
  };
  _exports.default = _default;
});