define("niffler/pods/application/route", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    routeAfterAuthentication: 'authorized.home',
    i18n: Ember.inject.service('i18n'),

    async model(params) {
      let {
        token,
        locale
      } = params;
      let session = Ember.get(this, 'session');
      let tokenService = Ember.get(this, 'tokenService');
      let i18n = Ember.get(this, 'i18n');
      await i18n.load(locale);

      if (Ember.isPresent(token)) {
        session.authenticate('authenticator:token', token).then(() => {
          Ember.set(tokenService, 'authenticatedByToken', true);
        });
      } else if (Ember.get(tokenService, 'authenticatedByToken')) {
        session.invalidate();
        Ember.set(tokenService, 'authenticatedByToken', false);
      }
    }

  });

  _exports.default = _default;
});