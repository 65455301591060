define("niffler/pods/components/notification-mask/notification/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "notification": "_notification_3at07x",
    "warning": "_warning_3at07x",
    "error": "_error_3at07x",
    "success": "_success_3at07x",
    "info": "_info_3at07x"
  };
  _exports.default = _default;
});