define("niffler/authenticators/token", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "niffler/config/environment", "fetch"], function (_exports, _oauth2PasswordGrant, _environment, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    authenticate(token) {
      return (0, _fetch.default)(`${_environment.default.APP.apiUrl}/validate?token=${token}`).then(response => {
        if (response.ok) {
          return {
            access_token: token,
            token_type: 'bearer'
          };
        } else {
          return Ember.RSVP.reject();
        }
      }).catch(() => Ember.RSVP.reject());
    }

  });

  _exports.default = _default;
});