define("niffler/pods/components/widgets/list/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jUr7QFVX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[27,[26,1,\"Expression\"],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[5,[27,[26,0,\"BlockHead\"],[]],null,[[\"route\"],[\"authorized.price-list\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[16,1,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[9,\"input\",true],[12,\"name\",\"list\",null],[12,\"type\",\"checkbox\",null],[10],[11],[1,1,0,0,\" \"],[9,\"label\",true],[10],[1,1,0,0,\"name\"],[11],[1,1,0,0,\" \"],[16,1,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"is-parent\",\"if\"]}",
    "meta": {
      "moduleName": "niffler/pods/components/widgets/list/item/template.hbs"
    }
  });

  _exports.default = _default;
});