define("niffler/pods/components/tree-render/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XdrELkRo",
    "block": "{\"symbols\":[\"item\",\"child\",\"@action\",\"@model\"],\"statements\":[[7,\"widgets/tree-item\",[],[[],[]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n\"],[5,[27,[26,4,\"BlockHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[27,[24,1],[\"title\"]],\"expected `item.title` to be a contextual component but found a string. Did you mean `(component item.title)`? ('niffler/pods/components/tree-render/template.hbs' @ L2:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"spam\",true],[13,\"onClick\",[31,68,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],[27,[24,3],[]],[27,[24,4],[]]],null],null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[27,[24,4],[\"name\"]]],[1,1,0,0,\"\\n\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[5,[27,[26,4,\"BlockHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[27,[24,1],[\"content\"]],\"expected `item.content` to be a contextual component but found a string. Did you mean `(component item.content)`? ('niffler/pods/components/tree-render/template.hbs' @ L9:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],[[27,[24,4],[\"children\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[7,\"tree-render\",[],[[\"@model\",\"@action\"],[[27,[24,2],[]],[31,250,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],[27,[24,3],[]]],null]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"-track-array\",\"each\",\"-assert-implicit-component-helper-argument\",\"component\"]}",
    "meta": {
      "moduleName": "niffler/pods/components/tree-render/template.hbs"
    }
  });

  _exports.default = _default;
});