define("niffler/models/product", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    epc: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    productCode: (0, _attr.default)('string'),
    discount: (0, _attr.default)('number'),
    entity: (0, _relationships.belongsTo)('entity'),
    uoms: (0, _attr.default)('map'),
    category: (0, _attr.default)('string'),
    uomsList: Ember.computed('uoms', {
      get() {
        let uoms = Ember.get(this, 'uoms') || {};
        return Object.entries(uoms).map(([, value]) => value.name).filter(item => item).filter((item, pos, array) => array.indexOf(item) === pos).map(value => ({
          name: value
        }));
      }

    }),
    uomsItems: Ember.computed('uoms', {
      get() {
        let uoms = Ember.get(this, 'uoms') || {
          uomMin: {},
          uomVol: {}
        };
        return [{
          udm: "UDMmin",
          name: uoms.uomMin.name
        }, {
          udm: "UDMemp",
          name: uoms.uomVol.name
        }];
      }

    })
  });

  _exports.default = _default;
});