define("niffler/pods/components/fragments/price-list/categories/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0rWopEzk",
    "block": "{\"symbols\":[\"Panel\",\"@readOnly\",\"@findCategoriesSystem\",\"@categories\",\"@fillCategory\",\"@onDelete\"],\"statements\":[[7,\"niffler/panel\",[],[[\"@title\"],[[31,25,8,[27,[26,0,\"CallHead\"],[]],[\"msj.categoria\"],null]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n  \"],[7,[27,[24,1],[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[7,\"fragments/price-list/categories/form\",[],[[\"@readOnly\",\"@findCategoriesSystem\",\"@categories\",\"@fillCategory\"],[[27,[24,2],[]],[31,187,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],[27,[24,3],[]]],null],[27,[24,4],[]],[31,278,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],[27,[24,5],[]]],null]]],null],[1,1,0,0,\"\\n\\n    \"],[7,\"fragments/price-list/categories/render\",[],[[\"@readOnly\",\"@categories\",\"@onDelete\"],[[27,[24,2],[]],[27,[24,4],[]],[31,438,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],[27,[24,6],[]]],null]]],null],[1,1,0,0,\"\\n  \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"localize\",\"action\"]}",
    "meta": {
      "moduleName": "niffler/pods/components/fragments/price-list/categories/template.hbs"
    }
  });

  _exports.default = _default;
});