define("niffler/pods/components/forms/input-search-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['search-box', 'form-input'],
    localClassNameBindings: ['designClass', 'grow', 'widthClass'],
    width: 0,
    focused: true,
    grow: false,
    design: 'default',
    designClass: Ember.computed('design', function () {
      return Ember.get(this, 'design') || 'default';
    }),
    isReadOnly: Ember.computed('readonly', 'disabled', function () {
      let readonly = Ember.get(this, 'readonly');
      let disabled = Ember.get(this, 'disabled');
      return readonly || disabled;
    }),
    widthClass: Ember.computed('width', function () {
      switch (Ember.get(this, 'width')) {
        case 1:
          return 'expand-eight';

        case 2:
          return 'expand-quarter';

        case 3:
          return 'expand-half';

        case 4:
          return 'expand-th-q';

        case 5:
          return 'expand-full';

        default:
          return 'expand-full';
      }
    }),
    actions: {
      chooseOption(policy, item) {
        let onSelect = Ember.get(this, 'onSelect');
        Ember.set(this, 'value', '');
        Ember.set(this, 'focused', false);
        Ember.set(this, 'items', null);
        return onSelect(policy, item);
      },

      setFocus(focused) {
        Ember.set(this, 'focused', focused);
      }

    }
  });

  _exports.default = _default;
});