define("niffler/services/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    notification: null,
    hasNotifications: Ember.computed('notification', function () {
      return Ember.get(this, 'notification');
    }),

    show(type, message, seconds) {
      Ember.set(this, 'notification', {
        type: type || 'info',
        message: message || 'Test'
      });
      let ttl = (seconds || 5) * 1000;
      Ember.run.later(this, () => {
        Ember.set(this, 'notification', null);
      }, ttl);
    },

    remove() {
      Ember.set(this, 'notification', null);
    }

  });

  _exports.default = _default;
});