define("niffler/pods/authorized/price_list/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "reportPl": "_reportPl_10nnay",
    "tableheader": "_tableheader_10nnay",
    "tablecontent": "_tablecontent_10nnay"
  };
  _exports.default = _default;
});