define("niffler/pods/components/fragments/entity/products/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I1BlIj3R",
    "block": "{\"symbols\":[\"item\",\"@product\",\"@products\",\"@fillProduct\",\"@findProducts\"],\"statements\":[[7,\"layout\",[],[[\"@direction\"],[\"row\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n  \"],[7,\"forms/input-search-box\",[],[[\"@oninput\",\"@value\",\"@placeholder\",\"@label\",\"@model\",\"@width\",\"@items\",\"@onSelect\",\"@onChange\"],[\"false\",[27,[24,2],[\"name\"]],[31,119,8,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]],\"msg.enterProductName\"],null],[31,171,8,[27,[26,1,\"CallHead\"],[]],[\"msg.product.name\"],null],[27,[24,2],[]],\"3\",[27,[24,3],[]],[31,279,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[27,[24,4],[]]],null],[31,317,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[27,[24,5],[]]],null]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n      \"],[9,\"span\",true],[10],[1,0,0,0,[27,[24,1],[\"productCode\"]]],[11],[1,1,0,0,\" - \"],[9,\"span\",true],[10],[1,0,0,0,[27,[24,1],[\"name\"]]],[11],[1,1,0,0,\"\\n  \"]],\"parameters\":[1]}]]],[1,1,0,0,\"\\n\\n  \"],[7,\"forms/input-text\",[],[[\"@value\",\"@placeholder\",\"@label\",\"@width\",\"@onChange\"],[[27,[24,2],[\"epc\"]],[31,519,8,[27,[26,1,\"CallHead\"],[]],[\"msg.enterProductEpc\"],null],[31,565,8,[27,[26,1,\"CallHead\"],[]],[\"EPC\"],null],\"2\",[31,611,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[31,619,3,[27,[26,3,\"CallHead\"],[]],[[27,[24,2],[\"epc\"]]],null]],null]]],null],[1,1,0,0,\"\\n\\n  \"],[7,\"forms/input-text\",[],[[\"@value\",\"@placeholder\",\"@label\",\"@width\",\"@onChange\"],[[27,[24,2],[\"productCode\"]],[31,718,8,[27,[26,1,\"CallHead\"],[]],[\"msg.enterProductCode\"],null],[31,765,8,[27,[26,1,\"CallHead\"],[]],[\"porderline.codprod\"],null],\"2\",[31,826,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[31,834,3,[27,[26,3,\"CallHead\"],[]],[[27,[24,2],[\"productCode\"]]],null]],null]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n\",\"localize\",\"action\",\"mut\"]}",
    "meta": {
      "moduleName": "niffler/pods/components/fragments/entity/products/form/template.hbs"
    }
  });

  _exports.default = _default;
});