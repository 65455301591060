define("niffler/pods/components/forms/multiple-price/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    items: [{
      udm: 'UDMmin'
    }, {
      udm: 'UDMemp'
    }],
    value: Ember.computed('items', function () {
      return Ember.get(this, 'value');
    }),
    actions: {
      change(object, property, value) {
        Ember.set(object, property, value);
        let onChange = Ember.get(this, 'onChange');

        if (onChange) {
          onChange(Ember.get(this, 'items'));
        }
      },

      onAdd() {
        let item = Ember.get(this, 'items');
        item.pushObject({});
      }

    }
  });

  _exports.default = _default;
});