define("niffler/pods/components/button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "button": "_button_19si2u",
    "button-default": "_button-default_19si2u",
    "button-disabled": "_button-disabled_19si2u",
    "button-confirm": "_button-confirm_19si2u",
    "button-danger": "_button-danger_19si2u",
    "button-ghost": "_button-ghost_19si2u"
  };
  _exports.default = _default;
});