define("niffler/pods/components/popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lRylJ3qq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"div\",true],[13,\"class\",[32,[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],[\"popup \",[27,[26,0,\"Expression\"],[]]],null]],[[\"from\"],[\"niffler/pods/components/popup/styles\"]]]]],null],[10],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[13,\"class\",[32,[[31,0,0,[27,[26,2,\"CallHead\"],[]],[\"popup-header\"],[[\"from\"],[\"niffler/pods/components/popup/styles\"]]]]],null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[27,[26,3,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n    \"],[9,\"button\",false],[14,\"class\",[32,[[31,0,0,[27,[26,2,\"CallHead\"],[]],[\"popup-close\"],[[\"from\"],[\"niffler/pods/components/popup/styles\"]]]]],null],[3,0,0,[27,[26,5,\"ModifierHead\"],[]],[[27,[24,0],[]],[27,[26,4,\"Expression\"],[]]],null],[10],[1,1,0,0,\"x\"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[13,\"class\",[32,[[31,0,0,[27,[26,2,\"CallHead\"],[]],[\"popup-content\"],[[\"from\"],[\"niffler/pods/components/popup/styles\"]]]]],null],[10],[1,1,0,0,\"\\n    \"],[16,1,null],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"height\",\"concat\",\"local-class\",\"title\",\"close\",\"action\"]}",
    "meta": {
      "moduleName": "niffler/pods/components/popup/template.hbs"
    }
  });

  _exports.default = _default;
});