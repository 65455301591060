define("niffler/transforms/date2", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    serialize(value) {
      //return JSON.stringify(value);
      return value;
    },

    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },

    deserialize(value) {
      let date = new Date(value);
      return [date.getFullYear(), this.padTo2Digits(date.getMonth() + 1), this.padTo2Digits(date.getDate())].join('-') + ' ' + [this.padTo2Digits(date.getHours()), this.padTo2Digits(date.getMinutes()), this.padTo2Digits(date.getSeconds())].join(':');
    }

  });

  _exports.default = _default;
});