define("niffler/pods/components/forms/input-search-box/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "search-box": "_search-box_xa14uk",
    "grow": "_grow_xa14uk",
    "search-icon": "_search-icon_xa14uk",
    "search-input": "_search-input_xa14uk _input_158mqq",
    "item-list": "_item-list_xa14uk",
    "element-item": "_element-item_xa14uk",
    "default": "_default_xa14uk",
    "input": "_input_xa14uk _input_158mqq",
    "label": "_label_xa14uk _label_158mqq",
    "form-input": "_form-input_xa14uk _form-input_158mqq",
    "required": "_required_xa14uk _required_158mqq",
    "expand-eight": "_expand-eight_xa14uk _expand-eight_158mqq",
    "expand-quarter": "_expand-quarter_xa14uk _expand-quarter_158mqq",
    "expand-half": "_expand-half_xa14uk _expand-half_158mqq",
    "expand-th-q": "_expand-th-q_xa14uk _expand-th-q_158mqq",
    "expand-full": "_expand-full_xa14uk _expand-full_158mqq",
    "input-error": "_input-error_xa14uk _input-error_158mqq",
    "error-message": "_error-message_xa14uk _error-message_158mqq",
    "visible": "_visible_xa14uk"
  };
  _exports.default = _default;
});