define("niffler/pods/components/panel/toolbar-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['toolbar-button'],
    localClassNameBindings: ['colorClass', 'buttonIcon', 'designClass'],
    color: 'secondary',
    buttonIcon: Ember.computed('icon', function () {
      let icon = Ember.get(this, 'icon');
      return icon ? `fa fa-${icon}` : 'fa fa-square';
    }),
    colorClass: Ember.computed('color', function () {
      return `color-${Ember.get(this, 'color')}`;
    }),
    designClass: Ember.computed('design', function () {
      let design = Ember.get(this, 'design');
      let readOnly = Ember.get(this, 'readonly');

      if (readOnly) {
        design = "readonly";
      }

      return design ? `design-${design}` : 'design-default';
    }),

    click() {
      let readOnly = Ember.get(this, 'readonly');

      if (!readOnly) {
        let action = Ember.get(this, 'action');

        if (action) {
          action();
        }
      }

      return false;
    }

  });

  _exports.default = _default;
});