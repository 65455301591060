define("niffler/pods/components/widgets/tree-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZbLqhYSa",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"div\",true],[13,\"class\",[32,[[31,0,0,[27,[26,0,\"CallHead\"],[]],[\"tree-item-button\"],[[\"from\"],[\"niffler/pods/components/widgets/tree-item/styles\"]]]]],null],[13,\"onclick\",[31,46,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"toggle\"],null],null],[12,\"type\",\"button\",null],[10],[1,1,0,0,\"\\n  \"],[16,1,[[31,91,4,[27,[26,3,\"CallHead\"],[]],null,[[\"title\"],[[31,103,9,[27,[26,2,\"CallHead\"],[]],[\"blank-template\"],null]]]]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"],[9,\"div\",true],[13,\"class\",[32,[[31,0,0,[27,[26,0,\"CallHead\"],[]],[\"tree-item-content\"],[[\"from\"],[\"niffler/pods/components/widgets/tree-item/styles\"]]]]],null],[10],[1,1,0,0,\"\\n  \"],[16,1,[[31,190,4,[27,[26,3,\"CallHead\"],[]],null,[[\"content\"],[[31,204,9,[27,[26,2,\"CallHead\"],[]],[\"blank-template\"],null]]]]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"action\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "niffler/pods/components/widgets/tree-item/template.hbs"
    }
  });

  _exports.default = _default;
});