define("niffler/pods/components/niffler/menu/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4ukOH3m3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"fa-icon\",[],[[\"@icon\"],[[27,[26,0,\"AppendSingleId\"],[]]]],null],[1,1,0,0,\"\\n\"],[16,1,null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\"]}",
    "meta": {
      "moduleName": "niffler/pods/components/niffler/menu/item/template.hbs"
    }
  });

  _exports.default = _default;
});